import { CubaApp } from '@cuba-platform/rest';
import { AlertProps } from '@material-ui/lab/Alert';
import { action, computed, extendObservable, observable } from 'mobx';
import { cubaREST } from '../..';
import { Account } from '../../cuba/entities/billing_Account';
import routes from '../../routing/routes';
import store from '../store';
import AccountStore from './account/account-store';
import ContractStore from './contract/contract-store';
import IcpStore from './icp/icp-store';
import InvoiceStore from './invoice/invoice-store';
import PricesStore from './prices/prices-store';
import SignupStore from './signup/signup-store';


class AppStore {

  public title?: string;
  public user?: any;
  public cubaRest?: CubaApp

  @observable
  resetToken?: string | null 

  @observable
  snackbarOpen?: boolean = false

  @observable
  loggingIn: boolean = false
  
  private promiseAccount?: Promise<Account> 

  public accountStore: AccountStore
  public contractStore: ContractStore
  public icpStore: IcpStore
  public invoiceStore: InvoiceStore
  public pricesStore: PricesStore
  public signupStore: SignupStore

  public snackBarAlert: { alertMessage: string, severity: AlertProps["severity"] } | undefined

  private connectionErrorCount: number


  constructor() {    
    this.accountStore = new AccountStore(this)
    this.contractStore = new ContractStore(this)
    this.icpStore = new IcpStore(this)
    this.invoiceStore = new InvoiceStore(this)
    this.pricesStore = new PricesStore(this)
    this.signupStore = new SignupStore(this)
    this.connectionErrorCount = 0

    //not sure if this is needed?
    extendObservable(this, {
      title: 'Ecosmart APP',
      user: undefined,
      cubaREST: CubaApp,
      account: null,
      accountBalance: computed
    });
  }


  setSnackbarState = action((open: boolean) => {
    this.snackbarOpen = open
  });

  publishSnackbarAlert = action((alertMessage: string, severity: AlertProps["severity"]) => {
    this.snackBarAlert = { alertMessage, severity }
    this.setSnackbarState(true)
  });


  setTitle = action((title: string | undefined) => {
    this.title = title;
  });

  setCubaRest = action((cubaRest: CubaApp | undefined) => {
    this.cubaRest = cubaRest;
  });  

  handleConnectionError = action((source:String) => {
    this.connectionErrorCount = this.connectionErrorCount + 1
    if(this.connectionErrorCount>2){
      this.publishSnackbarAlert('Connection lost to server, logging out', 'warning')
      this.doLogoutActions()      
    }
  });  

  doLogin = action((userName: string, password: string) => {
    this.loggingIn = true
    cubaREST.login(userName, password).then((result) => {
      cubaREST.getUserInfo().then((userInfo) => {
        cubaREST.loadEntity('billing_Account', userInfo.id, {view:'account-rest-view'}).then((acc) => {
          this.accountStore.account = acc as Account
        })
      })
    }, (rejected) => {    
      if (rejected) {
        if (rejected.response?.status === 400) {
          this.publishSnackbarAlert('Login failed - bad credentials', 'error')
        } else {
          if (rejected) {
            let typeError = rejected as TypeError
            if (typeError.message === 'Failed to fetch') {
              this.publishSnackbarAlert('Unable to connect to the server, please try again later', 'error')
            } else {
              this.publishSnackbarAlert('Login failed', 'error')
            }
          }
        }
      }
      this.doLogoutActions()
    }).catch((error) => {
      alert(error)
    }).finally(() => {
      this.loggingIn = false
    })
  });

  attemptAutoLogin = action(() => {
    this.loggingIn = true
    cubaREST.getUserInfo().then((userInfo) => {
      this.promiseAccount = cubaREST.loadEntity('billing_Account', userInfo.id,{view:'account-rest-view'})      
      this.promiseAccount.then((acc) => {
      this.accountStore.account = acc 
      this.loggingIn = false     
      }, () => {
        console.log('error caught in auto login')
        this.doLogoutActions()
      })
    }).catch((error:Error)=>{
      console.log('error caught in auto login - 2')
      this.doLogoutActions()
    })    
    .finally(() => {
      this.loggingIn = false
    })
  })

  @action
  doLogoutActions() {
    localStorage.removeItem("billing_cubaAccessToken");
    localStorage.clear();
    store.app.accountStore = new AccountStore(store.app)
    store.app.icpStore = new IcpStore(store.app)
    store.app.contractStore = new ContractStore(store.app)
    store.app.invoiceStore = new InvoiceStore(store.app)
    store.app.pricesStore = new PricesStore(store.app)

    this.accountStore.account = null
    this.connectionErrorCount = 0
    store.router.goTo(routes.login)
  }

  get getCubaRest() {
    return this.cubaRest
  }

  get accountIsLoaded() {
    return this.accountStore.account != null
  }

}


export default AppStore;