import { Button, Card } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { StoreContext } from '../../context/store-context';
import routes from '../../routing/routes';


const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'center'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button:{
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },   
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
  
    margin: {
        margin: theme.spacing(1)
    },
    textField: {
        width: "25ch"
    }, 

    spinner: {
        color: '#1a90ff',
        animationDuration: '550ms',
    },
    title: {
        fontSize: 18,
        textAlign: 'center'
    },
}));

interface State {
    amount: string;
    password: string;
    showPassword: boolean;
    confirmPassword: string;
    showConfirmPassword: boolean;
    submitDisabled: boolean
    settingPassword: boolean
    passwordPassesRegexp: boolean
    confirmPasswordPassesRegexp: boolean
}

export const ForgotPasswordPage = observer(() => {
    const classes = useStyles();
    const store = useContext(StoreContext);
    const { router: { goTo } } = store;
    const [token, setToken] = React.useState<string | number | boolean | undefined>()

    const [values, setValues] = React.useState<State>({
        amount: "",
        password: "",
        showPassword: false,
        confirmPassword: "",
        showConfirmPassword: false,
        submitDisabled: true,
        settingPassword: false,
        passwordPassesRegexp: false,
        confirmPasswordPassesRegexp: false
    });

   // const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})");
    //const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");

     //8 chars + minimum of 1 upper, 1 lower and 1 number
     const customRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

    React.useEffect(()=>{
        setToken(store.router.params?.token)
    },[store.router.params])
    
    React.useEffect(() => {
        let tempValues = {...values};
        console.log(customRegex.test(values.confirmPassword))

        if (customRegex.test(values.password)) {
            tempValues = {...tempValues, passwordPassesRegexp: true}
            
        } else {
            tempValues = {...tempValues, passwordPassesRegexp: false}
        }   
        if (customRegex.test(values.confirmPassword)) {
            tempValues = {...tempValues, confirmPasswordPassesRegexp: true}           
        } else {
            tempValues = {...tempValues, confirmPasswordPassesRegexp: false}
        }      
        if (values.password && values.confirmPassword && values.password === values.confirmPassword) {
            tempValues = {...tempValues, submitDisabled: false}
        } else {
            if (values.submitDisabled === false) {
                tempValues = {...tempValues, submitDisabled: true}
            }
        }   

        setValues(tempValues)
    }, [values.password, values.confirmPassword]);
   


    const handleSubmit = () => {
        setValues({ ...values, settingPassword: true });
        store.app.cubaRest?.invokeService<boolean>('billing_AccountService', 'resetPasswordFromToken', { resetTokenString: token, password: values.password })
            .then((res) => {
                if (res.toString() === 'true') {
                    store.app.publishSnackbarAlert('Password changed successfully', 'success')
                    goTo(routes.login)
                }
                else {
                    store.app.publishSnackbarAlert('Unable to change password', 'error')
                }
            }).finally(() => {
                setValues({ ...values, settingPassword: false });
            })
    }

    const handleChange = (prop: keyof State) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword, showConfirmPassword: !values.showConfirmPassword });
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    return (

        <Container component="main" maxWidth="xs" className={classes.root}>
            <CssBaseline />
           
            <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h3" align={'center'} style={{ padding: 2 }}>
                    Set New Password
            </Typography>
            <Typography style={{ padding: 12 }}>
                   Once the password has enough strength the box will turn green.
            </Typography>
                <Card>
                    <Container component="main" maxWidth="xs" >
                        <CssBaseline />
                        <FormControl
                            className={clsx(classes.margin, classes.textField)}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="password">
                                Password
                            </InputLabel>
                            <OutlinedInput
                                id="password"
                                type={values.showPassword ? "text" : "password"}
                                value={values.password}
                                onChange={handleChange("password")}
                                labelWidth={70}
                                color={values.passwordPassesRegexp ? 'secondary' : 'primary'}

                            />

                        </FormControl>
                        <FormControl
                            className={clsx(classes.margin, classes.textField)}
                            variant="outlined"
                        >
                            <InputLabel htmlFor="confirm-password">
                                Confirm Password
                            </InputLabel>
                            <OutlinedInput
                                id="confirm-password"
                                type={values.showConfirmPassword ? "text" : "password"}
                                value={values.confirmPassword}
                                onChange={handleChange("confirmPassword")}
                                labelWidth={70}
                                color={values.confirmPasswordPassesRegexp ? 'secondary' : 'primary'}
                            />

                        </FormControl>
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </Container>
                    <Button  onClick={handleSubmit} color="secondary" disabled={values.submitDisabled} style={{alignSelf:'self-end'}}>
                        Set Password
                    </Button>
                </Card>

            </div>
        </Container>
    );
})

export default ForgotPasswordPage