import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../../../../../../context/store-context';
import { Balances } from '../../../../../../cuba/entities/billing_Balances';
import PaymentDetails from './PaymentDetails/PaymentDetails';


const debitColor = "#0EA9E1" //'#118cd8'//
const creditColor = "#65e03c"// #0EE147" //'#14c823'//

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignContent: 'center',
      display: 'block',
      alignItems: 'center'
    },
    centerAlign: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems:'center',
      paddingTop:  theme.spacing(2),
      paddingBottom:  theme.spacing(1),
      paddingLeft:  theme.spacing(2),
      paddingRight:  theme.spacing(2),
      cursor: 'pointer'
    },   
    endButtons:{
      display:'flex',
      alignItems:'flex-end',      
      '& > *': {
        margin: theme.spacing(1),
      },
    }
  }));

const CurrentBalance = observer((props: any) => { 
  const classes = useStyles();
  const store = useContext(StoreContext);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if(store.app.accountStore.account){
      store.app.cubaRest?.invokeService('billing_AccountService', 'getBalanceForAccountId', 
      { accountId: store.app.accountStore.account?.id 
      }, 
      { handleAs: 'json' })
      .then((result) => {       
        store.app.accountStore.setBalances(result as Balances)
      }).catch(()=>{
          store.app.handleConnectionError('useEffect current-balance')
      })
    }   
  }, [store.app, store.app.accountStore, store.app.accountStore.account, store.app.cubaRest]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
  <div>
   <Box className={classes.root}>
      <Box display="flex" justifyContent="center" m={1} p={2} flex="space-between" >      
        {store.app.accountStore.balances?
        <Box className={classes.centerAlign} boxShadow={5} minWidth={200}
          flexDirection="column"
          justifyContent="center"
          borderRadius={30}
          onClick={handleClickOpen}
        >
            <Typography 
            component="h2"
            gutterBottom
            variant="h5"
            align='center'
            style={{fontSize:16}}
          >
            ACCOUNT BALANCE
        </Typography >
          <Typography variant="h4" color="textPrimary" style={{fontSize:22}}>           
              ${Math.abs(store.app.accountStore.balances?.accountBalance).toFixed(2)}          
          </Typography>
          {store.app.accountStore.balances?.accountBalance<0?
           <Typography variant="h6" style={{ fontWeight:500, color: store.app.accountStore.balances?.accountBalance<0?creditColor:debitColor, textAlign:'right'} }>           
           (IN CREDIT)
           </Typography>
          :       
           <Button color="secondary" onClick={handleClickOpen}>
           DUE FOR PAYMENT
           </Button>  
          }
        </Box>
        :null}
      </Box>
    </Box>
 <Dialog
 fullScreen={fullScreen}
 open={open}
 onClose={handleClose}
 aria-labelledby="responsive-dialog-title"
>
 <DialogTitle id="responsive-dialog-title">
    Payment Options
    </DialogTitle>
 <DialogContent>
   <DialogContentText>
     We currently only accept payment by direct credit into our bank account.  We hope to have other options available in the near future.
   </DialogContentText>
   <PaymentDetails/>
 </DialogContent>
 <DialogActions>
   <Button onClick={handleClose} color="primary" autoFocus size='large'>
     Ok
   </Button>
 </DialogActions>
</Dialog>
</div>
  )
})

export default CurrentBalance;



