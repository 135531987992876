import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import SpendTable from '../../../../../../components/SpendTable/SpendTable';
import { StoreContext } from '../../../../../../context/store-context';
import { BillingPeriodSpend } from '../../../../../../cuba/entities/billing_BillingPeriodSpend';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 500,
      display:'flex'
    },
    root: {
      alignContent: 'center',
      display: 'block',
      alignItems: 'center'
    },
    vertAlign: {
      display: "flex",
      alignItems: "center",
      padding: 15
    },
    dateDescription: {
      fontSize: 14,
      textAlign: 'center'
    },
    topBorder: {
      borderColor: 'gray',
      borderTopWidth: 2
    }, // or borderTop: '1px solid red'
    warning: {
      borderColor: 'gray',
      borderTopWidth: 2,
      color: 'red',
      fontSize: 10
    }, // or bord
    tableBorders: {
      borderWidth: 1,
      borderColor: 'gray',
      borderStyle: 'solid',
      borderTopWidth: 10
    }, // or borderTop: '1px solid red'
    tableNoBorder: {
      borderWidth: 1,
      borderColor: 'red',
      borderStyle: 'solid',

    }, // or borderTop: '1px solid red'
    circle: {
      strokeLinecap: 'round',
      color: '#1a90ff',
      animationDuration: '550ms',

    },

    spinner: {
      color: '#1a90ff',
      animationDuration: '550ms',
    },
    details: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      padding: theme.spacing(3),
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 151,
    },
    title: {
      fontSize: 14,
      textAlign: 'center'
    },
    controls: {
      alignContent: 'center',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: 30,

    },
    playIcon: {
      height: 50,
      width: 50,
      color: "textPrimary"
    },
    centerAlign: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      cursor: 'pointer'
    },
    palette: {
      secondary: {
        main: '#f44336',
      },
    },
    appBar: {
      background: 'white',
      position: '-webkit-sticky',
      top: 50,
      bottom: 20,
      zIndex: 500,
    },   

  }));


const CurrentSpend = observer((props: any) => {

  const classes = useStyles();
  const store = useContext(StoreContext);
  const moment = require('moment');
  const [spendTableOpen, setSpendTableOpen] = React.useState(false)

  useEffect(() => {
    let currentDateMinusOneDay = new Date()
    currentDateMinusOneDay.setDate(currentDateMinusOneDay.getDate() - 1)
    let check = store.app.contractStore.billingSpendLastUpdated ? currentDateMinusOneDay > store.app.contractStore.billingSpendLastUpdated : false
    if (store.app.contractStore.currentContract) {
      if (!store.app.contractStore.currentBillingPeriodSpend || check) {
        store.app.cubaRest?.invokeService<BillingPeriodSpend>('billing_AccountRestService', 'getCurrentBillingPeriodSpend', { contract: store.app.contractStore.currentContract },
          { handleAs: 'json' }).then((res) => {
            store.app.contractStore.currentBillingPeriodSpend = res
            store.app.contractStore.billingSpendLastUpdated = new Date()
          }).catch(() => {
            store.app.handleConnectionError('use-effect current-spend')
          })
      }
    }
    return () => {
      //this runs when the page unmounts for cleanup actions       
    }
  }, [store.app, store.app.contractStore.billingSpendLastUpdated,
  store.app.contractStore.currentBillingPeriodSpend,
  store.app.cubaRest,
  store.app.contractStore.currentContract]);


  const handleCardClick = (params: any) => {
    handleClickOpen()
  }

  const handleClickOpen = () => {
    //store.app.contractStore.get()
    setSpendTableOpen(true)
    //setOpen(true);
  };

  const getBillingPeriodString = () => {
    return ` ${moment(store.app.contractStore.currentBillingPeriodSpend?.billingPeriod?.fromDate).format('DD MMM')} to ${moment(store.app.contractStore.currentBillingPeriodSpend?.billingPeriod?.toDate).format('DD MMM, yyyy')}`
  }

  if (store.app.contractStore.currentBillingPeriodSpend) {

    return (
      <div className={classes.root}>
        <Typography component="h5" variant="h5" align='center'>
          Your current spend
          </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          for period {getBillingPeriodString()}
        </Typography>

        {store.app.contractStore.currentBillingPeriodSpend ?

          <Box className={classes.root}>
            <Box display="flex" justifyContent="center" m={1} p={2} flex="space-between" >

              <Box className={classes.centerAlign} boxShadow={5} minWidth={200}
                flexDirection="column"
                justifyContent="center"
                borderRadius={30}
                onClick={handleClickOpen}
              >
                <Typography
                  component="h2"
                  gutterBottom
                  variant="h5"
                  align='center'
                  style={{ fontSize: 16 }}
                >
                  CURRENT SPEND
                </Typography >
                <Typography variant="h4" color="textPrimary" style={{ fontSize: 22 }}>
                  {store.app.contractStore.currentBillingPeriodSpend.total < 0 ?
                    `$(${(Math.round(Math.abs(store.app.contractStore.currentBillingPeriodSpend.total) * 100) / 100).toFixed(2)}) CREDIT`
                    :
                    `$${(Math.round(store.app.contractStore.currentBillingPeriodSpend.total * 100) / 100).toFixed(2)}`
                  }
                </Typography>
                <Button color="primary" onClick={handleCardClick} size='large'>View Breakdown</Button>
              </Box>
            </Box>
          </Box>


          : null}

        <SpendTable {...{
          open: spendTableOpen,
          setOpen: setSpendTableOpen, 
          billingPeriodSpend: store.app.contractStore.currentBillingPeriodSpend}}/>        

      </div>
    );
  }
  else {
    return null
  }
}
);



export default CurrentSpend;
