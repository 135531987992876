import { action, autorun, observable } from 'mobx';
import { FinalPrice } from '../../../cuba/entities/base/ecos_FinalPrice';
import { FinalPriceAvgByDate } from '../../../cuba/entities/base/ecos_FinalPriceAvgByDate';
import { FinalPriceAvgByMonth } from '../../../cuba/entities/base/ecos_FinalPriceAvgByMonth';
import store from '../../store';
import AppStore from '../app-store';

const moment = require('moment');

class PricesStore {    

  
  @observable
  pricesDate: Date 

  @observable
  finalPricesSet: {date:Date, finalPrices:FinalPrice[]} | undefined

  @observable
  finalPricesMonthSet: {date:Date, finalPrices:FinalPriceAvgByDate[]} | undefined

  @observable
  finalPricesYearSet: {date:Date, finalPrices:FinalPriceAvgByMonth[]} | undefined

  
  @observable
  finalPricesScope: String = 'HALF-HOURLY'

  @observable
  latestFinalPricesDate: Date | undefined

  @observable
  averagePrice: number | undefined

  public appStore?: AppStore  

  constructor(appStore: AppStore) {
    this.appStore = appStore
    this.pricesDate = new Date()

    autorun(() => {
      if (this.appStore?.icpStore.currentIcpList) {
        this.appStore.cubaRest?.invokeService('billing_AccountService', 'getLatestFinalPricesDate', {}).then((result) => {
          this.latestFinalPricesDate = result as Date
          this.pricesDate = new Date(moment(result as Date).format('yyyy-MM-DD'))          
        }).catch(() => {
          store.app.handleConnectionError('PricesStore: autorun')
        })
      }
    })

  }

  updateFinalPricesForDate = action((date:Date) => {
    if (this.appStore?.icpStore.currentIcpList) {
    let formattedDate = moment(date).format('YYYY-MM-DD')
      this.appStore?.cubaRest?.query('ecos_FinalPrice', 'getFinalPricesForGxpAndDate', { gxp: store.app.icpStore.currentIcpList?.pointOfConnection, date:formattedDate },{}).then((result) => {
        let priceList:FinalPrice[] = result as FinalPrice[]
        priceList = priceList.sort(function (a, b) {
          return (a.period! > b.period!) ? 1 : -1
        })
        let totalOfPrices:number = 0
        priceList.map((price)=>{
          price.price! = (price.price!/10)
          totalOfPrices = totalOfPrices+price.price!
          return null
        })     
       // this.pricesDate = new Date(formattedDate)
        this.averagePrice = totalOfPrices/priceList.length
        this.finalPricesSet = {date:new Date(formattedDate), finalPrices:priceList}        
      }).catch(() => {
        store.app.handleConnectionError('PricesStore: updateFinalPricesForDate')
      })
    } 
  })


updateFinalPricesForMonth = action((date:Date) => {
  if (this.appStore?.icpStore.currentIcpList) {
  let formattedDate = moment(date).format('YYYY-MM-DD')
  let newDate:Date = new Date(formattedDate)
  let month = newDate.getMonth() + 1
  let year = newDate.getFullYear()
    this.appStore?.cubaRest?.query('ecos_FinalPriceAvgByDate', 'getFinalPricesForGxpAndMonth', { gxp: store.app.icpStore.currentIcpList?.pointOfConnection,month:month, year:year },{})
    .then((result) => {
      let priceList:FinalPriceAvgByDate[] = result as FinalPriceAvgByDate[]
      let totalOfPrices:number = 0
      priceList.map((price)=>{
        price.price! = (price.price!/10)
        totalOfPrices = totalOfPrices+price.price!
        return null
      })     
      //this.pricesDate = new Date(formattedDate)
      this.averagePrice = totalOfPrices/priceList.length
      this.finalPricesMonthSet = {date:new Date(formattedDate), finalPrices:priceList}        
    }).catch(() => {
      store.app.handleConnectionError('PricesStore: updateFinalPricesForMonth')
    })
  } 
})

updateFinalPricesForYear = action((date:Date) => {
  if (this.appStore?.icpStore.currentIcpList) {    
    let formattedDate = moment(date).format('YYYY-MM-DD')
  let newDate:Date = new Date(moment(date).format('YYYY-MM-DD'))
  let year = newDate.getFullYear()
    this.appStore?.cubaRest?.query('ecos_FinalPriceAvgByMonth', 'getFinalPricesForGxpAndYear', { gxp: store.app.icpStore.currentIcpList?.pointOfConnection, year:year },{})
    .then((result) => {
      let priceList:FinalPriceAvgByMonth[] = result as FinalPriceAvgByMonth[]
      let totalOfPrices:number = 0
      priceList.map((price)=>{
        price.price! = (price.price!/10)
        totalOfPrices = totalOfPrices+price.price!
        return null
      }) 
     // this.pricesDate = new Date(formattedDate)    
      this.averagePrice = totalOfPrices/priceList.length
      this.finalPricesYearSet = {date:new Date(formattedDate), finalPrices:priceList}        
    }).catch(() => {
      store.app.handleConnectionError('PricesStore: updateFinalPricesForYear')
    })
  } 
})
}

export default PricesStore
