import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import GaugeChart from 'react-gauge-chart';
import { StoreContext } from '../../../../../../context/store-context';

const useStyles = makeStyles({
    root: {

        alignContent: 'center',
        display:'block',
        alignItems:'center'

    },
    title: {
        fontSize: 14,
        textAlign: 'center'
    },
    detail: {
        fontSize: 16,
        textAlign: 'center',
        padding:0
    },
});


export const FiveMinutePricesGauge = observer(() => {
    const store = useContext(StoreContext);
    const classes = useStyles();

    const calculatePercentage = () => {
        let price = store.app.icpStore.currentFiveMinutePrice?.price
        let calc = Math.round((((100 / 30) * price) / 100) * 10) / 10
        return calc > 1 ? 1 : calc
    }

    const formatTimestamp = (date: Date) => {
        const moment = require('moment');
        return moment(date).format('hh:mm a')
    }

    return (
        store.app.icpStore.currentFiveMinutePrice ?

            <div className={classes.root}>
          <Typography component="h5" variant="h5" align='center'>
                  Spot Price
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
                Last updated {formatTimestamp(store.app.icpStore.currentFiveMinutePrice?.timeStamp)}
          </Typography>
                <Box display="flex" justifyContent="center" m={1} p={0} style={{paddingBottom:0}}>
                    <div style={{maxWidth:450}}>
                    <GaugeChart id="gauge-chart5"
                        nrOfLevels={420}
                        arcsLength={[0.3, 0.5, 0.2]}
                        colors={['#5BE12C', '#F5CD19', '#EA4228']}
                        percent={calculatePercentage()}
                        animate={true}
                        arcPadding={0.02}
                        textColor='black'
                        needleColor='#bcbcbc'
                        formatTextValue={(() => `${Math.round((store.app.icpStore.currentFiveMinutePrice?.price) * 100) / 100}c`)}
                    />  
                    </div>  
                    </Box>                          
            </div>
            : null
    )

})



export default FiveMinutePricesGauge