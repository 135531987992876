import { action, autorun, observable } from 'mobx';
import { FiveMinutePrice } from '../../../cuba/entities/base/ecos_FiveMinutePrice';
import { IcpList } from '../../../cuba/entities/base/ecos_IcpList';
import { AccountHhrImportExportView } from '../../../cuba/entities/billing_AccountHhrImportExportView';
import { AccountHhrReadsView } from '../../../cuba/entities/billing_AccountHhrReadsView';
import store from '../../store';
import AppStore from '../../stores/app-store';


const moment = require('moment');

interface MeterRead {
  meterDescription: string | null | undefined;
  period: number | null | undefined;
  kwh: number | null | undefined;
}

class IcpStore {

  @observable
  public currentIcpList?: IcpList | undefined

  @observable
  public currentFiveMinutePrice?: FiveMinutePrice

  @observable
  public currentHhrReadsSet?: AccountHhrReadsView[]

  @observable
  public currentHhrTotalsSet?: AccountHhrImportExportView[]

  @observable
  usageOverviewDates?: { fromDate: Date, toDate: Date } | undefined

  @observable
  usageOverviewChartIsLoading?: boolean | false

  @observable
  public currentMeterreadsChannels?: String[]

  @observable
  public currentMeterReadings?: MeterRead[]

  @observable
  meterReadsDate?: Date | null | undefined

  @observable
  meterReadsGraphData?: { channels: string[], data: any[] }

  private appStore?: AppStore

  constructor(appStore: AppStore) {
    this.appStore = appStore


    autorun(() => {
      if (this.currentIcpList) {
        this.fetchInterval = null;
        this.initFiveMinutePriceTimer()

      }
    })


    autorun(() => {
      if (this.appStore?.contractStore?.currentContract) {
        this.appStore?.contractStore?.appStore?.cubaRest?.invokeService('billing_AccountService', 'getLatestReadsDateForContract', { contract: this.appStore?.contractStore.currentContract })
        .then((result) => {
          var latestDate = result as Date
          var startDate = new Date(latestDate.toString())
          startDate.setDate(startDate.getDate() - 7);
          this.usageOverviewDates = { fromDate: startDate, toDate: latestDate }
        }).catch(() => {
          store.app.handleConnectionError('autorun-icp-store')
        })
      }
    })

    autorun(() => {
      if (this.usageOverviewDates) {
        this.getHhrUsageForDateRange(this.usageOverviewDates.fromDate, this.usageOverviewDates.toDate)
      }
    })

  }

  private fetchInterval: any | undefined

  //sets to the latest icp list
  setCurrentIcpList = action(() => {
    this.appStore?.cubaRest?.query('ecos_IcpList', 'latestIcpList', { icpIdentifier: this.appStore.contractStore.currentContract?.icp?.id },)
    .then((result) => {
      this.currentIcpList = result[0] as IcpList
    }).catch(() => {
      store.app.handleConnectionError('setCurrentIcpList')
    })
  })

  //this is triggered once the current icp list has been set
  private initFiveMinutePriceTimer() {
    this.updateFiveMinutePrice(this.currentIcpList?.pointOfConnection); // initial fetch
    this.fetchInterval = setInterval(() => this.updateFiveMinutePrice(this.currentIcpList?.pointOfConnection), 60000);
  }


  updateMeterReadsChart = action((date: Date | undefined | null) => {

    if (!date) {
      if (!this.usageOverviewDates) {
        this.initUsageOverviewDates()
      }
      date = this.usageOverviewDates?.toDate
    }

    const moment = require('moment');
    store.app.cubaRest?.query(AccountHhrReadsView.NAME, 'getHhrReadsForAccount', { readDate: moment(date).format('YYYY-MM-DD') })
    .then((res) => {

      let array = res as AccountHhrReadsView[]
      let objects = new Set<string | null | undefined>()
      let chans: any = []
      let data: any = []

      array.map((item: AccountHhrReadsView) => {
        let chanDesc: string = item.meterSerial + '-' + item.meterChannel + ' ' + item.registerContentCode
        objects.add(chanDesc)
        data.push({ period: item.period, [chanDesc]: item.kwh })
        return null
      })
      objects.forEach((o) => {
        if (o) {
          chans.push(o)
        }
      })
      this.meterReadsGraphData = { channels: chans, data: data }
      this.meterReadsDate = date
    }).catch(() => {
      store.app.handleConnectionError('updateMeterReadsChart')
    })
  })

  updateFiveMinutePrice = action((gxp: string | null | undefined) => {
    if (gxp && store.app.accountStore.account) {
      this.appStore?.cubaRest?.query('ecos_FiveMinutePrice', 'getFiveMinutePriceForGxp', { gxp: store.app.icpStore.currentIcpList?.pointOfConnection },)
      .then((result) => {
        if(!result){
          store.app.handleConnectionError('updateFiveMinutePrice')
        }
        this.currentFiveMinutePrice = result[0] as FiveMinutePrice
      }).catch((error: Error) => {
        store.app.handleConnectionError('updateFiveMinutePrice-catch')
      })
    } else if (!store.app.accountStore.account) {
      this.fetchInterval = null
    }
  })

  getHhrReadsSetForDate = action((date: Date) => {

    date = moment(date).format('YYYY-MM-DD')
    this.appStore?.cubaRest?.query(AccountHhrReadsView.NAME, 'getHhrReadsForAccount', { readDate: date }).then((res) => {
      this.currentHhrReadsSet = res as AccountHhrReadsView[]
    }).catch((error: Error) => {
      store.app.handleConnectionError('getHhrReadsSetForDate')
    })
  })

  initUsageOverviewDates = action(() => {
    this.appStore?.contractStore?.appStore?.cubaRest?.invokeService('billing_AccountService', 'getLatestReadsDateForContract', { contract: this.appStore?.contractStore.currentContract }).then((result) => {
      let fromDate: Date = new Date((result as string).replace(' ', 'T'))
      let toDate: Date = new Date((result as string).replace(' ', 'T'))
      fromDate.setDate(fromDate.getDate() - 7);
      this.usageOverviewDates = { fromDate: fromDate, toDate: toDate }

    }).catch(() => {
      store.app.handleConnectionError('initUsageOverviewDates')
    })
  })


  getHhrUsageForDateRange = action((fromDate: Date, toDate: Date) => {
    this.usageOverviewChartIsLoading = true
    fromDate = moment(fromDate).format('YYYY-MM-DD')
    toDate = moment(toDate).format('YYYY-MM-DD')
    this.appStore?.cubaRest?.query(AccountHhrImportExportView.NAME, 'getHhrTotalsByDateForAccount', { readStartDate: fromDate, readEndDate: toDate }).then((res) => {
      this.currentHhrTotalsSet = res as AccountHhrImportExportView[]
    }).catch(() => {
      store.app.handleConnectionError('getHhrUsageForDateRange')
    })    
    .finally(() => {
      this.usageOverviewChartIsLoading = false
    })
  })
}

export default IcpStore
