import { action, autorun, observable } from 'mobx';
import { Invoice } from '../../../cuba/entities/billing_Invoice';
import { Line } from '../../../cuba/entities/billing_Line';
import { formatDate } from '../../../util/DateUtility';
import AppStore from '../app-store';

class InvoiceStore {

  @observable
  invoices?: Invoice[] | null

  @observable
  invoiceDataRows?: any = [];

  @observable
  selectedInvoice?: Invoice | undefined

  @observable
  lines?: Line[] | undefined

  public appStore?: AppStore

  constructor(appStore: AppStore) {
    this.appStore = appStore

    autorun(() => {
      if (this.invoices) {
        let dataRows: any = [];
        this.invoices.forEach((item, i) => {
          dataRows.push(createData(item.id, null, `$${item.total}`, formatDate(item.billingPeriod?.fromDate), formatDate(item.billingPeriod?.toDate)));
        });
        this.invoiceDataRows = dataRows
      }
    })

    function createData(id: string | undefined, invoiceNumber: number | null | undefined, total: string, fromDate: string, toDate: string) {
      return { id, invoiceNumber, fromDate, toDate, total };
    }
  }

  fetchInvoices = action(() => {
    this.appStore?.cubaRest?.loadEntities('billing_Invoice', { view: 'invoice-customer-rest-view', sort: '-billingPeriod.fromDate', limit: 20, /* offset: 6 */ })
    .then((response) => {
      let retrieved = response as Invoice[]
      //sort invoice lines
      retrieved.map((invoice) => {
        invoice.lines = invoice.lines?.sort(function (a, b) {
          return (a.lineDescription! > b.lineDescription!) ? 1 : -1
        })
        return null
      })
      this.invoices = retrieved
    }).catch(() => {
      this.appStore?.handleConnectionError('Invoice Store: fetchInvoices')
    })
  });


  setSelectedInvoice = action((invoiceId: string) => {
    this.appStore?.cubaRest?.loadEntity(Invoice.NAME, invoiceId, { view: 'invoice-report-view' }).then((result) => {
      this.selectedInvoice = result as Invoice
    }).catch(() => {
      this.appStore?.handleConnectionError('Invoice Store: setSelectedInvoice')
    })
  });

}


export default InvoiceStore
