import { action, autorun, observable } from 'mobx';
import { BillingPeriodSpend } from '../../../cuba/entities/billing_BillingPeriodSpend';
import { BillingPeriodTotalConsumption } from '../../../cuba/entities/billing_BillingPeriodTotalConsumption';
import { Contract } from '../../../cuba/entities/billing_Contract';
import { Line } from '../../../cuba/entities/billing_Line';
import store from '../../store';
import AppStore from '../app-store';

class ContractStore {

  @observable
  contracts: Contract[] | undefined

  @observable
  currentContract: Contract | undefined

  @observable
  calculatingSpend: boolean = false

  @observable
  calculatingBillingPeriod: boolean = false

  @observable
  daySpendObject?: { date: Date, subTotal: string, gst: string, total: string, lines: Line[] }


  @observable
  billingPeriodTotalConsumptions?:  BillingPeriodTotalConsumption[] | undefined

  @observable
  currentBillingPeriodSpend?: BillingPeriodSpend 

  @observable
  billingSpendLastUpdated?: Date 
  
  public appStore?: AppStore

  constructor(appStore: AppStore) {
    this.appStore = appStore

    autorun(() => {
      if (this.appStore?.accountStore.account && !this.contracts) {
        this.updateContracts()
      }
    })

    autorun(() => {
      if (this.contracts) {
        let cc: Contract | undefined
        this.contracts.forEach((contract) => {
          if (!cc) {
            cc = contract
          } else {
            if (cc.startDate < contract.startDate) {
              cc = contract
            }
          }
        })
        this.currentContract = cc
      }
    })

    autorun(() => {
      if (this.currentContract) {        
        this.appStore?.icpStore.setCurrentIcpList()
      }
    })  
  }

  

  updateBillingPeriodConsumptionTotals = action(() => {
    this.appStore?.cubaRest?.invokeService<BillingPeriodTotalConsumption[]>('billing_AccountRestService', 'getBillingPeriodTotalConsumptionList', { billingPeriod: store.app.contractStore.currentBillingPeriodSpend?.billingPeriod },
    { handleAs: 'json' }).then((res) => {
      this.billingPeriodTotalConsumptions = res
    }).catch(() => {
      store.app.handleConnectionError('updateBillingPeriodConsumption contract-store')
    })    
    .finally(() => {
  });
  })  

  generateDaySpendLines = action((date: Date) => {
    this.calculatingSpend = true
    store.app.cubaRest?.invokeService('billing_AccountService', 'calculateSpendForDate', { contract: this.currentContract, date },
      { handleAs: 'json' }).then((res) => {
        var lines: Line[] = res as Line[]
        let subTotal: number = 0 
        for (let line of lines) {
          subTotal = subTotal + (line.quantity * line.rate)
        }
        var totalPlusGst: string = (subTotal * 1.15).toFixed(2)
        lines = lines.sort(function (a, b) {
          return (a.lineDescription! > b.lineDescription!) ? 1 : -1
        })
        this.daySpendObject = { date: date, subTotal: subTotal.toFixed(2), gst: (subTotal * .15).toFixed(2), total: totalPlusGst, lines: lines }
      }).finally(() => {
        this.calculatingSpend = false
      })
  }) 

  updateContracts = action(() => {
    this.appStore?.cubaRest?.loadEntities('billing_Contract').then((response) => {
      this.contracts = response as Contract[]
    }).catch(() => {
      store.app.handleConnectionError('updateContracts contract-store')
    })
  });
}


export default ContractStore
